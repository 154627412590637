
.formheading {
    margin-bottom: $content-padding;
    padding-top: $content-padding;
    padding-bottom: $content-padding;
    padding-left: $content-padding;
    padding-right: $content-padding;
    border-radius: $border-radius;
    background-color: $gray-lightest;
}

.formheading-title {
    display: block;
    line-height: $headings-line-height;
    margin-bottom: 0;
    font-size: 18px;
    color: $subsidiary;
    font-weight: 700;
}

.formheading-subtitle {
    display: block;
    font-size: 15px;
    color: $subsidiary-light;
    font-weight: 600;
    margin-bottom: 0;
}