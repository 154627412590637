a,
.btn-link {
    color: var(--primary);

    &:hover, &:focus {
        color: var(--primary-dark);
    }
}

/**
 *  FORM
 */

.c-checkbox input[type="checkbox"]:checked + span, .c-checkbox input[type="radio"]:checked + span, .c-radio input[type="checkbox"]:checked + span, .c-radio input[type="radio"]:checked + span {
    border-color: var(--primary);
    background-color: var(--primary);
}

.c-checkbox:hover span, .c-radio:hover span {
    border-color: var(--primary);
}

.form-control:focus {
    border-color: var(--primary);
}


/**
 *  Varia
 */

.alert-outline-primary {
    background-color: var(--primary-lightest);
    border-color: var(--primary);
    color: var(--primary-dark);
}

.weekwidgetday.is-active {
    background: var(--primary);
}

.spinner-wrapper {  
    .ball-clip-rotate-multiple {
        div {
            border-color: var(--primary) transparent !important;
        }
    }
}
  
.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover, &:focus {
        background-color: var(--primary-dark);
        border-color: var(--primary-dark);
        box-shadow: none;
    }

    &:active {
        background-color: var(--primary-dark) !important;
        border-color: var(--primary-dark) !important;
        box-shadow: none !important;
    }

    &.disabled, &:disabled {
        background-color: var(--primary-light) !important;
        border-color: var(--primary) !important;
    }
}

/**
 *  SWAL
 */

 .swal-button:not([disabled]) {
    background-color: var(--primary);
}

.swal-button:not([disabled]):hover {
    background-color: var(--primary-dark);
}