.heading {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    
    margin: -$content-padding;
    margin-bottom: $content-padding;
    padding: $content-padding;
    font-weight: normal;
    background-color: transparent;

    min-height: 54px;

    &.heading-nomargin {
        margin-bottom: 0;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $content-padding;
        right: $content-padding;
        height: 1px;
        background-color: $subsidiary-lightest;
    }
}

.heading-start {
    flex: 0 1 auto;
    width: 25%;
    min-width: 35px;
}

.heading-end {
    flex: 0 1 auto;
    width: 25%;
    min-width: 35px;
}

.heading-content {
    flex: 1 1 auto;
    width: 100%;
    text-align: center;
}

.heading-title {
    line-height: $headings-line-height;
    margin-bottom: 0;
    font-size: 18px;
    color: $subsidiary;
    font-weight: 700;
}

.heading-subtitle {
    font-size: 15px;
    color: $subsidiary-light;
    font-weight: 600;
    margin-bottom: 0;
}
