.weekwidget {}

.weekwidgetday-indicator {
    align-self: flex-start;
}

.weekwidget-header {
}

.weekwidget-flex-date {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.weekwidget-date {
    min-width: 20%;
    padding-bottom: 1em;
}

.weekwidget-flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $content-padding / 2;
    border: 1px solid $gray-lightest;
    border-radius: 3px;
    padding: 2px;

    @media (min-width: 380px) {
        padding: 4px;
    }
}

.weekwidget-actions {
    flex: 0 1 auto;
}

.weekwidget-days {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        flex: 1 1 auto;
        margin-left: 1px;
        margin-right: 1px;
    }
}

.weekwidget-action {
    order: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    border: 0;
    border-radius: $border-radius;
    color: inherit;
    font: inherit;
    text-align: center;
    line-height: 1;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    width: 100%;
    min-width: 28px;
    min-height: 28px;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    &:hover {
        background-color: $subsidiary-lightest;
    }

    &:active {
        background-color: transparent;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    @media (min-width: 380px) {
        min-width: 36px;
        min-height: 36px;
    }
}

.weekwidgetday {
    order: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: $gray-lightest;
    border: 0; //1px solid rgba(#000000, 0.1);
    border-radius: $border-radius;
    color: inherit;
    font: inherit;
    text-align: center;
    line-height: 1;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    width: 100%;
    min-width: 28px;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    position:relative;

    &:hover, &:focus {
        background-color: $subsidiary-lighter;
    }

    &.is-active {
        background: $primary;
        color: $white;
    }

    @media (min-width: 380px) {
        min-width: 36px;
    }

    .has-slots {
        background:red;
        border-radius: 10px 10px 10px 10px;
        color:white;
        padding:5px 5px;
        float: right;
    }
}


.weekwidgetday-day {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.weekwidgetday-date {
    font-size: 1.2rem;
    font-weight: 600;
}

.weekwidgetday-month {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.weekwidget-content {
    position: relative;
    z-index: 0;
}

.weekwidget-loading {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba($white, 0.75);

    &[hidden] {
        display: none;
    }
}

.weekwidgetoptions {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: -0.4rem;
}

.weekwidgetoption {
    display: block;
    flex: 0 1 auto;
    flex-basis: calc(100% / 6 - 0.4rem);
    line-height: 1;
    font-size: $font-size-base;
    font-weight: 700;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-left: 0.4rem;
    margin-bottom: 0.4rem;
    text-align: center;
    color: $body-color;
    border: 1px solid $subsidiary-lightest;
    border-radius: $border-radius;
    text-decoration: none;

    &:hover, &:focus {
        background-color: $subsidiary-lightest;
        border-color: $subsidiary-lighter;
        color: $body-color;
        text-decoration: none;
    }

    &:active {
        background-color: $subsidiary-lighter;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}