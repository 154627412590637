.appointment-type {
    h4 {
        margin-bottom: 15px;
    }

    &-block {
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 15px;

        background-color: #e2edff;

        border-radius: 5px;

        &__arrow {
            float: left;
            width: 2%;

            @include media-breakpoint-down(md) {
                margin-right: 10px;
            }

            @include media-breakpoint-between(lg, xl) {
                margin-right: 10px;
            }
        }

        &__header {
            .cta {
                float: right;
                color: white;
            }
        }
    }
}

.subcategory-badge {
    text-align: center;
    border-radius: 10px;
    padding: 2px 10px;
    font-size: .9rem;
    
    &:not(:first-child) {
        margin-left: 5px;
    }

    &-large {
        @extend .subcategory-badge;
        margin-left: 0;
        padding: 4px 10px;
        font-size: 1.1rem;
    }
}
