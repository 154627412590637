.profile-footer {
  position: absolute;
  margin-left: -20px;
  width: 100%;
  background-color: $gray-lightest;
  z-index: 1;
  padding: 25px;
}

.category-container {
    .category-item {
        min-width: 15%;
        float: left;
        margin-right: 10px;
        height: 50px;
        text-align: center;
        border-radius: 10px;
        line-height: 3rem;
      }
}