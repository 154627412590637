/* ========================================================================
   Component: chart-easypie
 ========================================================================== */

// Makes possible to show the percetage
// centered in the middle of the pie
.easypie-chart {
    display: inline-block;
    position: relative;
    padding: 0 6px;

    span {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        margin-left: -50%;
        height: 30px;
        margin-top: -15px;
        font-size: 20px;
    }
    canvas {
        max-width: 100%;
    }

    &.easypie-chart-lg {
        span.easypie-chart-percentage {
            font-size: 40px;
            margin-top: -30px;
            line-height: 40px;
        }

        span.easypie-chart-text {
            font-size: 15px;
            color: $subsidiary-lightest;
            margin-top: 10px;
        }
    }

    &.easypie-chart-md {
        span {
            font-size: 30px;
            margin-top: -25px;
            line-height: 40px;
        }
    }
}
