.slotoptions {
    margin-bottom: 4px;
}

.slotoptions-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    background: $gray-lightest;
    border-radius: $border-radius;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 380px) {
        flex-flow: wrap;
    }
}

.slotoptions-media {
    flex: 0 0 auto;
}

.slotoptions-action {
    flex: 0 0 auto;

    @media (max-width: 380px) {
        margin-top: 4px;
        width: 100%;
    }
}

.slotoptions-title {
    flex: 1 1 auto;
    line-height: 1;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
}

.slotoptions-name {
    display: block;
    margin-bottom: 0;
    font-weight: 600;
    font-size: $font-size-base;
}

.slotoptions-availability {
    color: $text-muted;
}

.slotoptions-body {
    padding-top: $content-padding;
    padding-bottom: $content-padding;

    &[hidden] {
    }
}