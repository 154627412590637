.choiceblocks {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;

    border: 1px solid $subsidiary-lighter;
    border-radius: $border-radius;
    padding-left: 2px;
    padding-right: 2px;
}

.choiceblocks-item {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;

    & + & {
        border-top: 1px solid $subsidiary-lighter;
        
    }

    &.active {
        background-color: #e4fae9;
    }
}

.choiceblock {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    color: inherit;
    font: inherit;
    text-align: inherit;
    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    position: relative;
    z-index: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;


    padding-top: $content-padding /2;
    padding-bottom: $content-padding /2;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: $border-radius - 0.1rem;

    transition: color 0.1s, background-color 0.1s;

    &:hover, &:focus {
        background-color: $gray-lightest;
    }

    &:active {
        background-color: $subsidiary-lightest;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

.choiceblock-content {
    flex: 1 1 auto;
    color: $subsidiary-light;

    > :last-child {
        margin-bottom: 0;
    }
}

.choiceblock-title {
    color: $subsidiary;
    font-size: 18px;
}

.choiceblock-icon {
    flex: 0 0 auto;
    color: $subsidiary-light;
    padding-top: 0.2rem;
}

.choiceblock-link {
    color: $subsidiary-light;

    .choiceblock:hover &, 
    .choiceblock:focus & {
        color: $subsidiary-light;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}